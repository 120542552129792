import "./styles.css";
import mainImage from "./nuvem.jpg";

const Header = () => {
  return (
    <header>
      <h1>Meu Super Blog!</h1>
      <img src={mainImage} alt="" />
    </header>
  );
};
export default Header;

import Header from "./components/Header/index";
import Footer from "./components/Footer/index";
import Post from "./components/Post/index";

const App = () => {
  return (
    <>
      <Header />

      <main>
        <Post title="Meu 1º Post" readingTime={2}>
          <p>Introdução</p>
          <em>Desenvolvimento da ideia</em>
          <p>Conclusão</p>
        </Post>

        <Post title="Meu 2º Post" readingTime={1}>
          <p>Introdução</p>
          <em>Desenvolvimento da ideia</em>
          <p>Conclusão</p>
        </Post>

        <Post title="Meu 3º Post" readingTime={5}>
          <p>Introdução</p>
          <em>Desenvolvimento da ideia</em>
          <p>Conclusão</p>
        </Post>
        <Post title="Meu 3º Post" readingTime={5}>
          <p>Introdução</p>
          <em>Desenvolvimento da ideia</em>
          <p>Conclusão</p>
        </Post>

        {/*<h3>Parei na aula Props</h3>*/}
      </main>

      <Footer />
    </>
  );
};

export default App;
